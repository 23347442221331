

@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');
.App {
  text-align: center;
  background:#a8b98e ;
background: #F3F0EC;
padding:0% 0; font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
font-size: 17px;
text-align: justify;



}

.thanknote{
  text-align:right;
  color:#B08047;
  font-size:small;

}
.breaker{
  display: none;
}
.thanknotebold{

  font-size: large;
}

.imagemobile{
display: none;
}

.tretmaniimgmob{
display: none;
}
.section{
  position: relative; /* mandatory */
}

.treatmensblock{
  display: block;

}
  
.handwritten{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  margin: 5px 10px;
  text-align: center;
  font-family: 'Crimson Text', serif;
  font-family: 'Sen', sans-serif;
  font-family: 'Poiret One', cursive;
  color:#B08047 ;
  font-size: large; 
}

.handwritten h4{ font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;text-transform: none; font-size: large; padding:0 5%;}
.handwritten h3 { text-transform: none; font-size: large; padding:0 5%;}


.section1{
 display:grid;
grid-template-columns: 1fr ;
margin: 0 2% ;
padding:0  10%;
}

.section2{
  display:grid;
  grid-template-columns: 2fr 1fr;
  margin: 2px 10px;
}

.section3{
display:grid;
grid-template-columns: 1fr 2fr;
margin: 5px 10px;
}

.section4{
text-align: center;
background:#B08047 ;
padding:0% 10% ;
color: #F3F0EC;
display: grid;  background:#a4b689 ;
/*grid-template-columns: 5fr 1fr;
*/

}


.section4 p{ padding: 0%;}

.textcontainer{
padding: 0 1%;
position: relative;
}


.divcontainer{
position: relative;
margin:0 3%;
top: 0%;
padding: 0 1%;
 /* border-bottom: solid rgb(0, 0, 0,0.1) 2px;*/
}

.sectioncontainer{
 position: relative;
display: flex;
justify-content: center;
}


.sectionimage{height: 350px;
display: flex;
background-color:'';
justify-content:center;
align-items:center;
pointer-events: none;
}


.navbar{
 display:grid;
grid-template-columns: 1fr 1fr;
margin:0 15%;
}

.icon{
  display: flex;
  justify-content: flex-start;align-items: start;
  padding: 5px;
  color: #B08047;font-family: 'Poiret One', cursive;
  text-decoration: none;
  font-size: large;
  margin: 4px 0;
}

.buttons{
  display: flex;
  justify-content: flex-end;align-items: end;
  padding: 5px;font-size: larger;
}
.buttonstyle{
  background-color: transparent; 
  color:#a4b689;
  color:#B08047 ;
  outline: none;
padding: 0 5px;
border : none;
margin:2px  2px;
font-family: 'Poiret One', cursive; border:solid #B08047 1px;
text-decoration: none;
}


.linkbuttonstyle{
outline: none;
padding: 0 5px;
margin:2px  2px;
font-family: 'Poiret One', cursive; 
margin-right:2%;
text-decoration: none;
border:solid #a4b689 1px;
color: #a4b689;
background-color: transparent;
}

.buttons :hover{
color:white ;text-decoration: none;
cursor: pointer;
background-color: #a4b689;
border: solid #a4b689 1px;
}

.injectionicon{
 border: solid transparent 1px;
}

.injectionicon :hover {
border: none;
scale: none;margin: 0; padding: 0;
}

.contact-section{
 text-align:center;
 justify-content: center;
 align-items:center;
 margin: 0; 
position: relative;
 display: flex;
 justify-content: center;
}





.section-galery{
 margin:0 5%;
justify-content: center;
align-content: center;
background-color: #a4b689;
}

.galeridiv{
padding-bottom:0%;
padding-top:0.5%;
display:grid;
grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
justify-content: center;
align-items: center;
text-align: center;
justify-items: center;
max-width: 100vw;
}

.galerimg{
max-width: 94%;
box-shadow: 1px 5px 8px rgb(128, 128, 128,.6);
margin:4%;
overflow: hidden;
opacity:.9 ;
transition: all .4s ease-in-out;
pointer-events: none;
}

.galerimg:hover {
filter:none;
overflow: hidden;
opacity:1;
cursor:pointer;
border-radius:0;
}


  .galeri-div{
    padding-bottom:0%;
    padding-top:0.5%;
    display:grid;

    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    text-align: center;
    justify-items: center;
    max-width: 100vw;
    }
    
    /*----------tretman galerija----------*/
    .galer-img{
    max-width: 94%;
    box-shadow: 1px 5px 8px rgb(128, 128, 128,.6);
    margin:4%;
    overflow: hidden;
    opacity:.9 ;
    transition: all .4s ease-in-out;
    pointer-events: none;
    }
    
    
      .galer-img:hover {
      filter:none;
      overflow: hidden;
      opacity:1;
      cursor:pointer;
      border-radius:0;
      }
    




/*-----smal screens------*/


@media(max-width:1600px){

  .treatmensblock{
  display: flex;    
  flex-direction: column-reverse; 
  }
    
  .navbar{ margin: 0 5%};
  
  .galerimg{
  width: 280px;
  }

   .section1{
    padding: 0 2%;
    }

   .section4{
    padding:0 2%;
    }

    .galerimg{
  margin: 3%;
  max-width: 95%;
  }
 
  .galer-img{
   margin: 3%;
  max-width: 95%;
   }

} 

@media(max-width:1400px){
  .galeri-div{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

.galer-img:nth-child(6){
  display:none;
   }
}
  
   
@media (max-width:1315px){
      
  .nameen{
   display: none;
     }
  
    }


@media (max-width:1280px){
  
  .App{
    padding: 0% 1px;
   }
    }
    
   
   
 @media (max-width:1200px){
      
  .namep{
   display: none;
     }
     .textbox{
      padding: 0 5%;
     }
    }


    @media (max-width:1050px){

   .sectionimage{display: none;}

  .imagemobilediv{
  display:grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-items: center;
 }
 
 .imagemobile{
  display: flex;
  width: auto;
  max-width: 90vw;
  }

 .section1{
  display: block;
  padding: 0 .5%;
   }
    
  .section2{display: block;}

  .section3{
    display: flex;     
    flex-direction: column-reverse;     
    }

    .section4{ 
      margin: 0; 
      padding: 1% 0%;
    }
   
    .section4 p{
       text-align: justify;
      }

    .pcontainer{
      border: none;
     }

    .divcontainer{
      border: none;
    }

    .sectionimage{
      width: 70%;
      height: auto;
    }
    
    .sectionimage1{
      width: 80%;
      height: auto;
    }
 
    .textbox{
      padding: 0 5%;
    }


    .galeridiv{
      padding-bottom:0%;
      padding-top:0.5%;
      display:grid;
      grid-template-columns: 1fr 1fr 1fr ;
      justify-content: center;
      align-items: center;
      text-align: center;
      justify-items: center;
      max-width: 100vw;
      }
      
    
     .galerimg{
      max-width: 95%;
      margin:3% 4%;
      }
    
    .galeri-div{
      padding-bottom:0%;
      padding-top:0.5%;
      display:grid;
      grid-template-columns: 1fr 1fr 1fr ;
      justify-content: center;
      align-items: center;
      text-align: center;
      justify-items: center;
      max-width: 100vw;
      }
  
      .galer-img{
      max-width: 95%;
      margin:3% 4%;
      }

      .galer-img:nth-child(6){
        display:flex;
         }
         .galerimg:nth-child(10){
          display:none;
           }
    }


 @media (max-width:850px){

    .handwritten h4{
     padding: 0 8%;
    }
  
    
            
   .textbox{
    padding: 0 5%;
    }

  .section1{
  padding: 0;
  margin: 0 1.5%;
  }
.tretmaniimgweb{
  display: none;
}
.tretmaniimgmob{
  display: block;
}
}


@media (max-width:600px){

  .galeridiv{
  padding-bottom:0%;
  padding-top:0.5%;
  display:grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  justify-items: center;
   }
            
   
   .galerimg:nth-child(2){
    display:block;
    }
            
  .galerimg{
   width: 94%;
   margin:1.5%;
  }
  .galerimg:nth-child(10){
    display:none;
     }
  
   .buttons{
    font-size: large;
     }



  .galeri-div{
  padding-bottom:0%;
  padding-top:0.5%;
  display:grid;
  grid-template-columns: 1fr 1fr  ;
  }
          
  }



        @media (max-width:450px){

.handwritten{
  font-size: medium;
}
          .handwritten h4{
            padding: 0 4%;
          }

          .contact-section{
            position: relative;
            display: flex;     flex-direction: column-reverse; 
       

          }   .handwritten p{
            text-align: justify;
          }
          .galerimg{
            width: 80%;
          }
          .textbox{
            padding:0 5%;
          }
          .divcontainer p{
            padding: 0 1%;
          }
          .buttons{
            font-size: medium;
          }
          .icon{
            font-size: medium;
          }

          .galeri-div{
            padding-bottom:0%;
            padding-top:0.5%;
            display:grid;
            grid-template-columns: 1fr  ;

            }
            
            
           .galer-img{
            margin:2%;
            overflow: hidden;
           }
            
           .galer-img:nth-child(5){
            display:none;
           }

           .galer-img:nth-child(6){
            display:none;
            }   
            
            .galer-img:nth-child(4){
             display:none;
            }
            .galeridiv{
              padding-bottom:0%;
              padding-top:0.5%;
              display:grid;
              grid-template-columns: 1fr 1fr ;
  
              }
              
              
             .galerimg{
              margin: 2% 1%;
              overflow: hidden;
              width: 100%;
             }

             .galerimg:nth-child(10){
              display:flex;
             }
             .thanknotebold{
              font-size: small;
          
             }
             .breaker{
              display: block;
              font-size: medium;
             }
        }


        
        
        @media (max-width:395px){
        
       .navbar{
        display: block; 
        
       }
       .icon{
        justify-content: center;
        font-size: medium;
       }
       .buttons{
        justify-content: center;
        font-size: medium ;
       }
     
      }